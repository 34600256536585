import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { useFormContext, useWatch } from 'react-hook-form';

import { capitalize } from 'lodash';

import { useGetPlansQuery } from 'redux/apis/users/users-api';

import { Grid } from '@mui/material';
import { Tag } from 'components/tag';
import { WithAccordionSwitch } from 'components/inputs/with-accordion-switch';
import { AutocompleteController } from 'controllers/autocomplete-controller';

import { enterprisePlansStyles } from './styles';

interface IEnterprisePlans {
  userEnterpriseProducts: number[] | [];
}

export const EnterprisePlans = ({ userEnterpriseProducts }: IEnterprisePlans) => {
  const plans = useGetPlansQuery()?.data?.items || [];

  const { control, setValue } = useFormContext();

  const [enterprisePlans, setEnterprisePlans] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const isMountRef = useRef(true);
  const enterprisePlansRef = useRef<any>([]);

  const enterpriseProducts = useWatch({ control, name: 'enterpriseProducts' });

  useLayoutEffect(() => setIsChecked(!!enterpriseProducts.length), []);

  useEffect(() => {
    return () => {
      isMountRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (plans && plans.length) {
      const filteredPlans = getEnterprisePlans(plans);
      enterprisePlansRef.current = filteredPlans;
      setEnterprisePlans(filteredPlans);
    }
  }, [plans]);

  // Get Enterprise Plans
  const getEnterprisePlans = (data: any) => {
    return data.filter((plan: any) => plan?.data?.isEnterprisePlan);
  };

  const handleEnterpriseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const ids = checked ? userEnterpriseProducts || [] : [];

    setValue('enterpriseProducts', ids);
    setIsChecked(checked);
  };

  const handleRemoveTags = (id: number, values: any) => {
    const filteredTags = values.filter((item: any) => item.id !== id);
    setValue('enterpriseProducts', filteredTags);
  };

  const handleInputClose = () => {
    setEnterprisePlans(enterprisePlansRef.current);
  };

  const getDebouncedSearchValue = (value: string) => {
    setLoading(true);

    const filteredPlans = enterprisePlansRef.current;

    const filteredEnterprisePlans = filteredPlans.filter((item: any) => {
      return item?.name?.toLowerCase().includes(value.toLowerCase());
    });

    setEnterprisePlans(filteredEnterprisePlans);

    setTimeout(() => isMountRef.current && setLoading(false), 100);
  };

  const isOptionEqualToValue = (option: any, optionValue: any) => option?.id === optionValue?.id;

  const getOptionLabel = (option: any) => {
    return `${option.name} (${capitalize(option.recurring)})`;
  };

  const renderSelectedTags = (values: any) => {
    return values.map((value: any) => (
      <Tag
        text={`${value.name} (${capitalize(value.recurring)})`}
        onDelete={() => handleRemoveTags(value.id, values)}
        key={value.id}
      />
    ));
  };

  const renderOptionContent = (option: any) => {
    return `${option.name} (${capitalize(option.recurring)})`;
  };

  return (
    <WithAccordionSwitch
      withTick
      label="Enterprise Plans"
      isCollapsed={isChecked}
      checked={isChecked}
      withController={false}
      onChange={handleEnterpriseChange}
    >
      <Grid sx={enterprisePlansStyles.container}>
        <AutocompleteController
          multiple
          fullWidth
          disableClearable
          disableCloseOnSelect
          notFoundLabel="No Enterprise Plans"
          label="Make Visible"
          placeholder="Select Plans"
          name="enterpriseProducts"
          isLoading={loading}
          defaultValue={[]}
          options={enterprisePlans || []}
          renderOptionContent={renderOptionContent}
          isOptionEqualToValue={isOptionEqualToValue}
          getOptionLabel={getOptionLabel}
          getDebouncedSearchValue={getDebouncedSearchValue}
          renderSelectedTags={renderSelectedTags}
          onInputClose={handleInputClose}
        />
      </Grid>
    </WithAccordionSwitch>
  );
};
